import React from 'react'
import PageTitle from '../../components/Services/Web_Development/PageTitle'


const WebDevelopment = () => {
    return (
        <main>
            <PageTitle />
        </main>
    )
}

export default WebDevelopment
