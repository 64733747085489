import React from 'react'
import PageTitle from '../../components/Services/Content_writing/PageTitle'

const ContentWriting = () => {
  return (
    <div>
      <PageTitle />
    </div>
  )
}

export default ContentWriting
