import React from 'react'

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const Process = () => {
    return (
        <section className='py-[70px] w-full flex items-center justify-center max-575:py-[50px]'>
            <div className='container flex flex-col items-center justify-center gap-y-16'>

                <div className="heading-wrap items-center">
                    <span className='section-sub-text'>our Process</span>
                    <h2 className='Section-heading'>
                        Get Around the Sourcebee Process
                    </h2>
                    <p className='section-discrption'>At Sourcebee, we follow a very careful method that is meant to give you the best possible results for your projects. A simplified process that leads to success!</p>
                </div>

                <div className="steps-wrap flex justify-between">

                    <ScrollAnimation animateIn="fadeIn" className="step">
                        <span className="step-count">
                            01
                        </span>
                        <span className="step-heading">
                            Discovery and Planning
                        </span>
                        <p>
                            First, we find out what your problems and goals are. We come up with a plan for success through in-depth talks.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeIn" className="step">
                        <span className="step-count">
                            02
                        </span>
                        <span className="step-heading">
                            Creative Development
                        </span>
                        <p>
                            We create solutions that are engaging and hit home with your audience, from web creation to graphic design.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeIn" className="step">
                        <span className="step-count">
                            03
                        </span>
                        <span className="step-heading">
                            Project Execution
                        </span>
                        <p>
                            We keep an eye on performance, gather data, and tweak tactics to get you the best return on investment (ROI).
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="fadeIn" className="step">
                        <span className="step-count">
                            04
                        </span>
                        <span className="step-heading">
                            Continuous support
                        </span>
                        <p>
                            We do more than just produce; we also offer ongoing help and maintenance. We're here to help you grow.
                        </p>
                    </ScrollAnimation>

                </div>

            </div>
        </section>
    )
}

export default Process
