import React from 'react'
import Section from '../components/Section'
import PageTitle from '../components/Projects_page/PageTitle'

const Projects = () => {
  return (
    <main>
      <PageTitle />
      <Section />
    </main>
  )
}

export default Projects
