import React from 'react'
import PageTitle from '../../components/Services/Ebook_Writing/PageTitle'

const EbookWriting = () => {
  return (
    <div>
      <PageTitle />
    </div>
  )
}

export default EbookWriting
