import React from 'react'
import PageTitle from '../../components/Services/Graphic_Design/PageTitle'

const GraphicDesign = () => {
  return (
    <div>
      <PageTitle />
    </div>
  )
}

export default GraphicDesign
