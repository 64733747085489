import React from "react";

import icon1 from '../../assets/Icons/Web Development.svg'
import icon2 from '../../assets/Icons/Graphic Design.svg'
import icon3 from '../../assets/Icons/SEO.svg'
import icon4 from '../../assets/Icons/Social Media Marketing.svg'
import icon5 from '../../assets/Icons/Content Writing.svg'
import icon6 from '../../assets/Icons/Ebook Writing.svg'

import { IoArrowForward } from "react-icons/io5";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";



const Services = () => {

  return (
    <section className='py-[70px] w-full flex items-center justify-center max-575:py-[50px]' id='services'>
      <div className='container flex flex-col items-center justify-center gap-y-[90px]'>

        <div className="heading-wrap items-center">
          <span className='section-sub-text'>Our Services</span>
          <h2 className='Section-heading'>
            Explore Our Expert Digital Services
          </h2>
          <p className='section-discrption'>Explore a wide range of services designed to boost your online presence. From web development to SEO, we provide tailored solutions to help your business thrive in the digital world.</p>
        </div>
        <div className="services-wrap">
          <ScrollAnimation animateIn="fadeIn" className="service" id="web-development">
            <div className="service-icon" id="web-development">
              <img src={icon1} alt="" />
            </div>
            <h3>Web Development</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>Wordpress</span>
              <span className='section-sub-text services'>Shopify</span>
              <span className='section-sub-text services'>PHP</span>
              <span className='section-sub-text services'>React.js</span>
            </div>
            <p>
              We create dynamic, adaptable websites tailored to your business needs. Our team ensures smooth user experiences and efficient system performance. Let our professional web Development services transform your online image.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" className="service" id="graphic-design">
            <div className="service-icon" id="graphic-design">
              <img src={icon2} alt="" />
            </div>
            <h3>Graphic Design</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>Illustrator</span>
              <span className='section-sub-text services'>Photoshop</span>
              <span className='section-sub-text services'>Figma</span>
              <span className='section-sub-text services'>Xd</span>
            </div>
            <p>
              Using tools like Illustrator, Photoshop, Figma, and XD, we create stunning graphics. Our team designs unique visuals that reflect your brand and engage your audience. Our logo design services enhance your visual appeal.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" className="service" id="seo">
            <div className="service-icon" id="seo">
              <img src={icon3} alt="" />
            </div>
            <h3>SEO</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>On-Page</span>
              <span className='section-sub-text services'>Off-Page</span>
              <span className='section-sub-text services'>Technical SEO</span>
              {/* <span className='section-sub-text services'>Xd</span> */}
            </div>
            <p>
              Our SEO services boost your online visibility with cutting-edge methods. We improve website speed, enhance search rankings, and increase organic traffic. Let our experts help you attract attention and maximize your online presence.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" className="service" id="social-media">
            <div className="service-icon" id="social-media">
              <img src={icon4} alt="" />
            </div>
            <h3>Social Media Marketing</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>Illustrator</span>
              <span className='section-sub-text services'>Photoshop</span>
              <span className='section-sub-text services'>Figma</span>
              <span className='section-sub-text services'>Xd</span>
            </div>
            <p>
              Our social media marketing services help your business reach more people. Our team creates engaging content and targeted ads for platforms like Facebook, Instagram, Twitter, and LinkedIn. Our professional services will enhance your social profiles.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" className="service" id="content-writing">
            <div className="service-icon" id="content-writing">
              <img src={icon5} alt="" />
            </div>
            <h3>Content Writing</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>Web Content</span>
              <span className='section-sub-text services'>Blog Posts</span>
              <span className='section-sub-text services'>Figma</span>
              {/* <span className='section-sub-text services'>Xd</span> */}
            </div>
            <p>
              We craft quality content that matches your brand's style. Our skilled writers enhance your online profile and engage readers with blog posts, stories, web content, and more. Let our writers strengthen your brand's message.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" className="service" id="ebook-writing">
            <div className="service-icon" id="ebook-writing">
              <img src={icon6} alt="" />
            </div>
            <h3>Ebook Writing</h3>
            <div className="services-wrap-inner">
              <span className='section-sub-text services'>Illustrator</span>
              <span className='section-sub-text services'>Photoshop</span>
              <span className='section-sub-text services'>Figma</span>
              <span className='section-sub-text services'>Xd</span>
            </div>
            <p>
              We excel at creating engaging and useful eBooks. Our professional writers craft well-researched material that speaks to your readers and establishes you as an authority. Let our skilled eBook writing services help your business stand out.
            </p>
            <div className="arrow-btn">
              <span>Read More</span>
              <IoArrowForward />
            </div>
          </ScrollAnimation>
        </div>

      </div>
    </section>
  )
}

export default Services
