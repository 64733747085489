import React from 'react'
import NopageContent from '../components/404_page/NopageContent'

const Nopage = () => {
  return (
    <main>
      {/* <PageTitle /> */}
      <NopageContent />
    </main>
  )
}

export default Nopage
