import React, { useState, useEffect } from 'react';

import { FaArrowUp } from "react-icons/fa";

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setScrollProgress(scrolled);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate stroke-dashoffset for the circular progress bar
    const radius = 24;
    const circumference = 2 * Math.PI * radius;
    const dashOffset = circumference - (scrollProgress / 100) * circumference;

    return (
        <aside className={`back-to-top ${isVisible ? 'active' : ''}`} onClick={scrollToTop}>
            <FaArrowUp className='icon-arrow-up'/>
            <svg height="50" width="50" className='back-to-top-progress'>
                <circle cx="25" cy="25" r="24" style={{ strokeDasharray: circumference, strokeDashoffset: dashOffset }}></circle>
            </svg>
        </aside>
    );
};

export default BackToTopButton;


