import React, { useState } from 'react';

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import Swal from 'sweetalert2'

const Loader = () => (
    <div className="loader">
        <div className="spinner"></div>
    </div>
);

const Contact = () => {

    const [loading, setLoading] = useState(false);


    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Show the loader
        const formData = new FormData(event.target);

        formData.append("access_key", "9d05dc84-4c5c-472e-961c-0d3e23a4ff67");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        setLoading(false); // Hide the loader

        if (res.success) {

            Swal.fire({
                title: "Success",
                text: "Your message has been sent successfully!",
                icon: "success"
            });

            document.getElementById("form").reset();

        }
    };

    //     const jotformEmbedCode = `
    //     <script type="text/javascript" src="https://form.jotform.com/jsform/241893015287461"></script>
    //   `;

    return (
        <section className='py-[70px] w-full flex items-center justify-center max-575:py-[50px]' id='contact'>
            <div className='container flex gap-16 items-center justify-center max-575:flex-col max-575:gap-8'>
                <ScrollAnimation animateIn="fadeIn" className='flex flex-col items-start w-[50%] gap-y-6 max-575:w-full max-575:items-center'>
                    <div className="heading-wrap items-start">
                        <span className='section-sub-text'>Contact Us</span>
                        <h2 className='Section-heading'>
                            Let’s Chat About Your Success
                        </h2>
                    </div>
                    <p className='max-575:text-center'>
                        Have questions or need assistance? Reach out to our friendly team and let’s start a conversation about how we can help your business succeed. We're here to support you every step of the way!
                    </p>
                    <div className='Contact-sec-socails flex items-center gap-3 max-575:items-center'>
                        <a href='https://www.facebook.com/Sourcebee/' target='blank' aria-label='Facebook'> <FaFacebookF className='' /></a>
                        <a href='https://www.instagram.com/Sourcebee/' target='blank' aria-label='Instagram'> <FaInstagram className='' /></a>
                        <a href='https://www.linkedin.com/company/theSourcebee/' target='blank' aria-label='Linkedin'> <FaLinkedinIn className='' /></a>
                        <a href='https://wa.me/923435355310' target='blank' aria-label='Whatsapp'> <FaWhatsapp className='' /></a>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="fadeIn" className='w-[50%] max-575:w-full'>
                    <form className="contact-form" id='form' onSubmit={onSubmit}>
                        <input type="hidden" name="subject" value="New Submission: Sourcebee Contact Form" />
                        <div className='flex gap-2'>
                            <input type="text" placeholder="Name*" className="form-input w-full" name='name' required />
                            <select name="service" className="form-input w-full" id='service' required>
                                <option value="" disabled selected>Required Service</option>
                                <option value="Web Development">Web Development</option>
                                <option value="Graphic Design">Graphic Design</option>
                                <option value="SEO">SEO</option>
                                <option value="Social Media Marketing">Social Media Marketing</option>
                                <option value="Content Writing">Content Writing</option>
                                <option value="Ebook Writing">Ebook Writing</option>
                            </select>
                        </div>
                        <div className='flex gap-2'>
                            <input type="email" placeholder="Email*" className="form-input w-full" name='email' required />
                            <input type="number" placeholder="Phone*" className="form-input w-full" name='phone' required />
                        </div>
                        <textarea placeholder="Message*" className="form-textarea" name='message' required />
                        <button type="submit" className="form-button">Submit</button>
                    </form>
                </ScrollAnimation>

                {/* <ScrollAnimation animateIn="fadeIn" className='w-[50%] max-575:w-full'>
                    <iframe
                        title="JotForm"
                        src="https://form.jotform.com/241893015287461"
                        frameBorder="0"
                        style={{ width: '100%', height: '500px' }}
                    />
                </ScrollAnimation> */}

                {loading && <Loader />}

            </div>
        </section>
    )
}

export default Contact
