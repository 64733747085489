import React from 'react'
import { Link } from 'react-router-dom'

import { IoArrowForward } from "react-icons/io5";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const Banner = () => {
  return (
    <section className='hero bg-[#f2f0e6] pt-[190px] pb-[120px] flex items-center justify-center max-575:pb-[70px]'>
      <div className='container flex items-center justify-center'>
        <ScrollAnimation animateIn="fadeIn" duration={2} className='w-[85%] text-center flex items-center justify-center flex-col gap-y-[30px] max-575:w-full'>
          <span className='section-sub-text'>
            Enhance Your Digital Presence today
          </span>
          <h1 className='text-6xl font-bold leading-tight capitalize tracking-[-1.5px] max-575:text-[38px]'>
            Sourcebee, Your Trusted Partner for Success in the Digital World
          </h1>
          <p className='text-lg'>
            Boost your organization with the most effective digital marketing services offered by Sourcebee. Increase your online presence, engagement and elevate your brand. Collaborate with us to access strategic solutions that are customized to your success.
          </p>
          <div className='flex justify-center items-center gap-4 max-575:flex-col'>
            <a href="#contact" className='btn-solid-yellow arrow-btn max-575:w-full'>Book a Consultation - It's free <IoArrowForward /></a>
            {/* <Link to="/" className='btn-solid-yellow arrow-btn max-575:w-full'>Book a Consultation - It's free <IoArrowForward /></Link> */}
            <a href="#services" className='btn-outline-yellow arrow-btn max-575:w-full'>Explore Our Services <IoArrowForward /></a>
            {/* <Link to="/" className='btn-outline-yellow arrow-btn max-575:w-full'>Explore Our Services <IoArrowForward /></Link> */}
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default Banner
