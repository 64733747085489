import React from 'react'

import { Link } from 'react-router-dom';

const NopageContent = () => {
    return (
        <section className="NopageContent_wrapper flex justify-center items-center">
            <div className="container flex flex-col justify-center items-center">
                <p className="text-8xl font-semibold text-[#FFAD0F]">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-black sm:text-7xl">Page not found!</h1>
                <p className="mt-6 text-xl leading-7 text-black">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/" className='btn-solid-yellow'>Go Back Home</Link>
                    <Link to="/" className='btn-outline-yellow'>Explore Our Services</Link>
                    {/* <a href="#" className="text-sm font-semibold text-gray-900">
                        Contact support <span aria-hidden="true">&rarr;</span>
                    </a> */}
                </div>
            </div>
        </section>
    )
}

export default NopageContent
