import React from 'react';
import { useEffect } from 'react';

import wordpress_logo from '../../assets/tech/wordpress.svg'
import css_logo from '../../assets/tech/css.svg'
import react_logo from '../../assets/tech/react.svg'
import shopify_logo from '../../assets/tech/shopify.svg'
import laravel_logo from '../../assets/tech/laravel.svg'
import codeigniter_logo from '../../assets/tech/codeigniter.svg'
import sql_logo from '../../assets/tech/sql.svg'
import figma_logo from '../../assets/tech/figma.svg'
import ai_logo from '../../assets/tech/ai.svg'

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const Technologies = () => {
    useEffect(() => {
        const scrollers = document.querySelectorAll(".scroller");

        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            addAnimation();
        }

        function addAnimation() {
            scrollers.forEach((scroller) => {
                scroller.setAttribute("data-animated", true);
                const scrollerInner = scroller.querySelector(".scroller__inner");
                const scrollerContent = Array.from(scrollerInner.children);

                scrollerContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    duplicatedItem.setAttribute("aria-hidden", true);
                    scrollerInner.appendChild(duplicatedItem);
                });
            });
        }
    }, []);

    return (
        <section className='tech-logos py-[5px] w-full flex flex-col items-center justify-center overflow-hidden'>

            <ScrollAnimation animateIn='fadeIn' className="scroller reverse" data-speed="slow">
                <ul className="tag-list scroller__inner">
                    <li><img src={wordpress_logo} alt="Wordpress" /></li>
                    <li><img src={css_logo} alt="" /></li>
                    <li><img src={react_logo} alt="" /></li>
                    <li><img src={shopify_logo} alt="" /></li>
                    <li><img src={laravel_logo} alt="" /></li>
                    <li><img src={codeigniter_logo} alt="" /></li>
                    <li><img src={sql_logo} alt="" /></li>
                    <li><img src={figma_logo} alt="" /></li>
                    <li><img src={ai_logo} alt="" /></li>
                </ul>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' className="scroller forwards" data-speed="slow">
                <ul className="tag-list scroller__inner">
                    <li><img src={wordpress_logo} alt="Wordpress" /></li>
                    <li><img src={css_logo} alt="" /></li>
                    <li><img src={react_logo} alt="" /></li>
                    <li><img src={shopify_logo} alt="" /></li>
                    <li><img src={laravel_logo} alt="" /></li>
                    <li><img src={codeigniter_logo} alt="" /></li>
                    <li><img src={sql_logo} alt="" /></li>
                    <li><img src={figma_logo} alt="" /></li>
                    <li><img src={ai_logo} alt="" /></li>
                </ul>
            </ScrollAnimation>

        </section>
    );
};

export default Technologies;
