import React from 'react'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IoArrowForward } from "react-icons/io5";

import legend1st from "../../assets/portfolio/portfolio_Legend1st.webp"
import DesertOasis from "../../assets/portfolio/portfolio_Desert Oasis.webp"
import Zeeshan from "../../assets/portfolio/portfolio_Zeeshan.webp"

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";


const Portfolio = () => {

  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='bg-gray-50 py-[70px] w-full flex items-center justify-center max-575:py-[50px]' id='projects'>
      <div className='container flex flex-col items-center justify-center gap-y-10'>

        <div className="heading-wrap items-center">
          <span className='section-sub-text'>Portfolio</span>
          <h2 className='Section-heading'>
            Explore Our Portfolio of Success Stories
          </h2>
          <p className='section-discrption'>Explore our portfolio to witness the success stories we've created. From dynamic websites to impactful marketing campaigns, see how we've helped businesses shine in the digital world.</p>
        </div>

        <ScrollAnimation animateIn='fadeIn' className="Portfolio-wrap w-full">
          <Slider {...settings}>
            <div className="Portfolio-single-wrap">
              <div className='Portfolio-single-left'>
                <h3 className='lg:w-4/5'>
                  Legend1st - Digital Marketing Agency in Dubai
                </h3>
                <div className='Portfolio-features-wrap'>
                  <span className='section-sub-text Portfolio-features'>Web Development</span>
                  <span className='section-sub-text Portfolio-features'>Graphic Design</span>
                  <span className='section-sub-text Portfolio-features'>Content Writing</span>
                </div>
                <p>

                  Legend1st drives success with innovative web solutions, mobile apps, marketing strategies, and SEO. With 10 years of experience, their expert team enhances online profiles, attracts more customers, and boosts brand recognition. They have become a trusted partner in many companies' pursuit of online prosperity and sustained growth.
                </p>
                <a href="https://legend1st.com/" className="btn-solid-yellow arrow-btn" target="blank">View Live<IoArrowForward /></a>
              </div>
              <div className='Portfolio-single-right'>
                <img src={legend1st} alt="" />
              </div>
            </div>
            <div className="Portfolio-single-wrap">
              <div className='Portfolio-single-left'>
                <h3>
                  Desert Oasis Tours LLC
                </h3>
                <div className='Portfolio-features-wrap'>
                  <span className='section-sub-text Portfolio-features'>Web Development</span>
                  <span className='section-sub-text Portfolio-features'>Graphic Design</span>
                  <span className='section-sub-text Portfolio-features'>SMM</span>
                </div>
                <p>
                  Desert Oasis Tours LLC offers unforgettable UAE adventures, guided by veteran tour experts. Founded by passionate travelers, they ensure authentic, enriching experiences. From Dubai's skyscrapers to the desert sands, their mission is to showcase the UAE's beauty and culture while ensuring your comfort and satisfaction.
                </p>
                <a href="https://desertoasis.ae/" className="btn-solid-yellow arrow-btn" target="blank">View Live<IoArrowForward /></a>
              </div>
              <div className='Portfolio-single-right'>
                <img src={DesertOasis} alt="" />
              </div>
            </div>
            <div className="Portfolio-single-wrap">
              <div className='Portfolio-single-left'>
                <h3>
                  Zeeshan Travel and Tourism LLC
                </h3>
                <div className='Portfolio-features-wrap'>
                  <span className='section-sub-text Portfolio-features'>Web Development</span>
                  <span className='section-sub-text Portfolio-features'>Graphic Design</span>
                  <span className='section-sub-text Portfolio-features'>SMM</span>
                </div>
                <p>
                Zeeshan Travel and Tourism LLC offers unforgettable Dubai and UAE experiences. From desert safaris to luxury tours, their expert team ensures seamless and memorable journeys. Founded with a passion for travel, they cater to all, providing personalized itineraries and exceptional service to showcase the magic of Dubai and beyond.
                </p>
                <a href="https://zeeshantours.com/" className="btn-solid-yellow arrow-btn" target="blank">View Live<IoArrowForward /></a>
              </div>
              <div className='Portfolio-single-right'>
                <img src={Zeeshan} alt="" />
              </div>
            </div>
          </Slider>
        </ScrollAnimation>
      </div>

    </section >
  )
}

export default Portfolio
