import React from 'react'

const PageTitle = () => {
    return (
        <section className='page-title-wrap'>
            <div className='page-title-wrap-inner'>
                <span className='section-sub-text breadcrumb'>
                    Home / Services / Web Development
                </span>
                <h1 className='page-title'>
                Social Media Marketing
                </h1>
            </div>
        </section>
    )
}

export default PageTitle
