import React from 'react'
import underconstrution from '../assets/under_construction.png'

const Section = () => {
  return (
    <section className='py-[70px] w-full flex items-center justify-center'>
      <div className='container flex items-center justify-center gap-x-[70px]'>
            <img src={underconstrution} alt="" />
      </div>
    </section>
  )
}

export default Section
