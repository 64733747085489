import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/home";
import Projects from "./pages/Projects";
import Nopage from "./pages/Nopage";

import WebDevelopment from "./pages/Services/WebDevelopment";
import GraphicDesign from "./pages/Services/GraphicDesign";
import SEO from "./pages/Services/SEO";
import SocialMediaMarketing from "./pages/Services/SocialMediaMarketing";
import ContentWriting from "./pages/Services/ContentWriting";
import EbookWriting from "./pages/Services/EbookWriting";

import BackToTopButton from "./components/BackToTopButton";
import WhatsAppFloatingBtn from "./components/WhatsAppFloatingBtn";

import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/graphic-design" element={<GraphicDesign />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
        <Route path="/content-writing" element={<ContentWriting />} />
        <Route path="/ebook-writing" element={<EbookWriting />} />
        <Route path="*" element={<Nopage />} />
        {/* <Route path="*" element={<Home />} /> */}
      </Routes>
      <BackToTopButton />
      <WhatsAppFloatingBtn />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
