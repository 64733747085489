import React, { useEffect } from 'react';

import desertoasislogo from '../../assets/client_logos/ka-desert-oasis-logo-webp.webp'
import zeeshanlogo from '../../assets/client_logos/ka-zeeshan-tours-logo-webp.webp'
import legend1stlogo from '../../assets/client_logos/ka-legend1st-logo-webp.webp'
import leseven8 from '../../assets/client_logos/ka-leseven8-logo-webp.webp'
import ssdlogo from '../../assets/client_logos/ka-ssd-apparel-imports-logo-webp.webp'

// import logo1 from '../../assets/logo_placeholder.png'
// import logo2 from '../../assets/logo_placeholder1.png'
// import logo3 from '../../assets/logo_placeholder2.png'

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const OurClients = () => {

    useEffect(() => {
        document.querySelectorAll('.client-logo').forEach(service => {
            service.addEventListener('mouseenter', () => {
              document.querySelectorAll('.client-logo').forEach(s => {
                if (s !== service) {
                  s.classList.add('dimmed');
                }
              });
            });
            service.addEventListener('mouseleave', () => {
              document.querySelectorAll('.client-logo').forEach(s => {
                s.classList.remove('dimmed');
              });
            });
          });
      }, []);

  
    return (
        <section className='bg-gray-50 py-[70px] w-full flex items-center justify-center max-575:py-[50px]'>
            <div className="container flex flex-col items-center justify-center gap-y-10">

                <div className="heading-wrap items-center">
                    <span className='section-sub-text'>Our Clients</span>
                    <h2 className='Section-heading'>
                    Our Clients, Partners in Success
                    </h2>
                    <p className='section-discrption'>We work closely with companies, building long-lasting relationships based on trust and results. We make sure that our solutions are tailored to their specific needs so that they can succeed in the digital world.</p>
                </div>
                    <div className="client-logo-wrap">
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={legend1stlogo} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={desertoasislogo} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={zeeshanlogo} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={leseven8} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={ssdlogo} alt="" /></ScrollAnimation>
                        {/* <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={logo3} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={logo1} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={logo2} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={logo3} alt="" /></ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" className="client-logo"><img src={logo1} alt="" /></ScrollAnimation> */}
                    </div>
            </div>
        </section>
    ) 
}

export default OurClients
