import React from 'react'
import PageTitle from '../../components/Services/SEO/PageTitle'

const SEO = () => {
  return (
    <div>
      <PageTitle />
    </div>
  )
}

export default SEO
