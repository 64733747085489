import React from 'react'

import aboutImg1 from '../../assets/aboutus_img1.webp'
import aboutImg2 from '../../assets/aboutus_img2.webp'
import aboutImg3 from '../../assets/rotate360.webp'

import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";

import { IoArrowForward } from "react-icons/io5";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const About = () => {
  return (
    <section className='py-[70px] w-full flex items-center justify-center max-575:py-[50px]' id='about-us'>
      <div className="container flex items-center justify-center gap-x-[70px] max-768:flex-col max-768:gap-8">
        <div className='flex w-[50%] relative max-768:w-full'>
          {/* <div className='aboutus-left-shape'></div> */}
          <ScrollAnimation animateIn="fadeIn">
            <div className='flex gap-x-[25px] max-575:gap-x-4'>
              <div className='about-img1 mt-6'>
                <img className='rounded-xl' src={aboutImg1} alt="" />
              </div>
              <div className='about-img2 mb-6'>
                <img className='rounded-xl' src={aboutImg2} alt="" />
              </div>
              <div className='rotate360wrap'>
                <div className='relative'>
                  <img className='rotate360' src={aboutImg3} alt="" />
                  <MdArrowOutward className='rotate360-icon' />
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn='fadeIn' className='flex flex-col items-start w-[50%] gap-y-6 max-768:w-full'>
          <div className="heading-wrap items-start">
            <span className='section-sub-text'>About us</span>
            <h2 className='Section-heading'>
              Who We Are and What We Do
            </h2>
          </div>
          <p>
            We really want to help businesses do well in the digital world here at Sourcebee. We focus on new ideas and making sure our clients are happy. That's why we offer cutting-edge services in web development, graphic design, SEO, social media marketing, content creation, and eBook writing. Our committed team uses both their imagination and technical know-how to come up with unique strategies that help businesses grow and go above and beyond what customers expect.
          </p>
          <div className='flex flex-col gap-y-3'>
            <div className='flex gap-x-2'>
              <IoMdCheckmarkCircleOutline className='text-yellow w-6 h-6' />
              <p className=''><span className='font-[500]'>Our Mission:</span> Helping businesses thrive with innovative digital solutions.</p>
            </div>
            <div className='flex gap-x-2'>
              <IoMdCheckmarkCircleOutline className='text-yellow w-6 h-6' />
              <p className=''><span className='font-[500]'>Our Team:</span> Skilled experts delivering tailored strategies for your success.</p>
            </div>
            <div className='flex gap-x-2'>
              <IoMdCheckmarkCircleOutline className='text-yellow w-6 h-6' />
              <p className=''><span className='font-[500]'>Our Values:</span> Prioritizing creativity, client satisfaction, and digital trends.</p>
            </div>
          </div>
          <a href="#services" className="btn-solid-yellow arrow-btn">Explore Our Services<IoArrowForward /></a>

        </ScrollAnimation>
      </div>
    </section >
  )
}

export default About
