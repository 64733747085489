import React from 'react'

import aboutImg1 from '../../assets/whyus2.webp'
import aboutImg2 from '../../assets/whyus1.webp'


import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";

import { IoArrowForward } from "react-icons/io5";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";


const Whyus = () => {
    return (
        <section className='py-[70px] w-full flex items-center justify-center max-575:py-[50px]'>
            <div className="container">
                <div className='why-us-top flex items-center justify-center gap-x-[70px] max-768:flex-col max-768:gap-8'>
                    <div className='flex w-[50%] relative max-768:w-full'>
                        {/* <div className='aboutus-left-shape'></div> */}
                        <ScrollAnimation animateIn="fadeIn">
                            <div className='flex gap-x-[25px] max-575:gap-x-4'>
                                <div className='about-img1 my-6 max-575:my-4'>
                                    <img className='rounded-xl' src={aboutImg1} alt="" />
                                </div>
                                <div className='about-img2'>
                                    <img className='rounded-xl' src={aboutImg2} alt="" />
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation animateIn='fadeIn' className='flex flex-col items-start w-[50%] gap-y-6 max-768:w-full'>
                        <div className="heading-wrap items-start">
                            <span className='section-sub-text'>why choose us</span>
                            <h2 className='Section-heading'>
                                Partner with Sourcebee to beat the digital battle!
                            </h2>
                        </div>
                        <p>
                            Feeling like the digital world is too much to handle? Our committed team at Sourcebee is here to help you. Our cutting-edge solutions, like visually stunning websites and images, change the way standard marketing is done.
                        </p>
                        <div className='flex flex-col gap-y-3'>
                            <div className='flex gap-x-2'>
                                <div>
                                    <IoMdCheckmarkCircleOutline className='text-yellow w-10 h-10' />
                                </div>
                                <div className='flex flex-col gap-y-1'>
                                    <span className='text-lg font-semibold'>Align Your Goals with Our Digital Experience</span>
                                    <p>With Sourcebee, you can get rid of confusion and start moving toward digital success. We match our digital skills with your goals to make sure you go above and beyond online.</p>
                                </div>
                            </div>
                            <div className='flex gap-x-2'>
                                <div>
                                    <IoMdCheckmarkCircleOutline className='text-yellow w-10 h-10' />
                                </div>
                                <div className='flex flex-col gap-y-1'>
                                    <span className='text-lg font-semibold'>Making Utilization of Technology</span>
                                    <p>For all your digital marketing needs, our huge collection of digital tools helps us come up with new ideas and workable plans. When you work with Sourcebee, you can reach your full online potential.</p>
                                </div>
                            </div>
                        </div>
                        <a href="#contact" className="btn-solid-yellow arrow-btn">Get a Quote Now<IoArrowForward /></a>

                    </ScrollAnimation>
                </div>
                <div className="why-us-bottom flex justify-between items-center pt-16">
                    <ScrollAnimation animateIn="fadeIn" className="milestone flex flex-col gap-[2px]">
                        <div className="milestone-top flex items-center justify-start gap-[12px]">
                            <FaCheckCircle />
                            <p className=''>8+</p>
                        </div>
                        <div className="milestone-bottom">
                            <p className=''>Years Of Experience</p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn" className="milestone flex flex-col gap-[2px]">
                        <div className="milestone-top flex items-center justify-start gap-[12px]">
                            <FaCheckCircle />
                            <p className=''>15+</p>
                        </div>
                        <div className="milestone-bottom">
                            <p className=''>Team Members</p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn" className="milestone flex flex-col gap-[2px]">
                        <div className="milestone-top flex items-center justify-start gap-[12px]">
                            <FaCheckCircle />
                            <p className=''>300+</p>
                        </div>
                        <div className="milestone-bottom">
                            <p className=''>Projects Completed</p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn" className="milestone flex flex-col gap-[2px]">
                        <div className="milestone-top flex items-center justify-start gap-[12px]">
                            <FaCheckCircle />
                            <p className=''>98%</p>
                        </div>
                        <div className="milestone-bottom">
                            <p className=''>Happy Clients</p>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </section>
    )
}

export default Whyus
