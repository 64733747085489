import React from 'react'

import Banner from '../components/homepage/Banner'
import Services from '../components/homepage/Services'
import Cta from '../components/homepage/Cta'
import Portfolio from '../components/homepage/Portfolio'
import About from '../components/homepage/About'
import Technologies from '../components/homepage/Technologies'
import OurClients from '../components/homepage/OurClients'
import Whyus from '../components/homepage/Whyus'
import Testimonials from '../components/homepage/Testimonials'
import Process from '../components/homepage/Process'
import Contact from '../components/homepage/Contact'



const home = () => {
  return (
    <main>  
      <Banner />
      <About />
      <Technologies />
      <Services />
      <Cta />
      <Portfolio />
      <Whyus />
      <Testimonials />
      <Process />
      <OurClients />
      <Contact />
    </main>
  )
}

export default home
