import React from 'react'
import Section from '../components/Section'
import PageTitle from '../components/About_page/PageTitle'

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const About = () => {
  return (
    <main>
      <PageTitle />
      <Section />

      <ScrollAnimation animateIn="fadeIn">
          <div className="industry-sec-title">
            <h2>Industries We Serve</h2>
            <p>We Have Worked Across Multiple Industries</p>
          </div>
        </ScrollAnimation>

        <div>
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" duration={2} delay={500} offset={100}>
            <h1>This text will fade in with a 2-second duration, a 500ms delay, and starts 100px before the viewport</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInLeft" animateOut="fadeOutLeft" duration={2} delay={500} offset={100}>
            <p>This paragraph will bounce in from the left with a 2-second duration, a 500ms delay, and starts 100px before the viewport</p>
          </ScrollAnimation>
        </div>

        <div>
          <ScrollAnimation animateIn="fadeIn">
            <h2>Fade In</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInDown">
            <h2>Fade In Down</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <h2>Fade In Up</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft">
            <h2>Fade In Left</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInRight">
            <h2>Fade In Right</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceIn">
            <h2>Bounce In</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInDown">
            <h2>Bounce In Down</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInUp">
            <h2>Bounce In Up</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInLeft">
            <h2>Bounce In Left</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInRight">
            <h2>Bounce In Right</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="zoomIn">
            <h2>Zoom In</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="slideInUp">
            <h2>Slide In Up</h2>
          </ScrollAnimation>
        </div>

    </main>
  )
}

export default About
