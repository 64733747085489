import React from 'react'
import Section from '../components/Section'
import PageTitle from '../components/Contact_page/PageTitle'

const Contact = () => {
  return (
    <main>
      <PageTitle />
      <Section />
    </main>
  )
}

export default Contact
