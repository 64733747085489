import React from 'react'
import { Link } from 'react-router-dom';

import site_logo_white from '../assets/white-logo.webp';

import { LuPhoneCall } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineMapPin } from "react-icons/hi2";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";


const Footer = () => {
    return (
        <footer className='bg-black text-white w-full flex justify-center items-center'>
            <div className="container">
                <div className="footer-main flex justify-center py-16 gap-x-20 max-1024:gap-x-12 max-768:flex-col max-768:gap-y-12">
                    <div className='footer-col-1 footer-col w-[35%] flex flex-col gap-y-7 max-768:w-full'>
                        <Link to="/">
                            <img src={site_logo_white} alt="" className='w-[75%] max-768:w-[50%] max-575:w-[75%]' />
                        </Link>
                        <p className='text-[16px]'>At Sourcebee, We help your business shine online. Whether you're new or experienced, we provide the right tools and support to boost your digital presence. Let’s work together to make your online goals a reality!</p>
                    </div>
                    <div className='footer-col-2 footer-col w-[15%] flex flex-col gap-y-6 mt-2 max-768:w-full'>
                        <h3 className='text-xl font-medium'>Quick Links</h3>
                        <nav>
                            <ul className='flex flex-col gap-y-3 '>
                                <li><Link to="/"><span className='menu-hover'>Home</span></Link></li>
                                <li><a href='#about-us'><span className='menu-hover'>About Us</span></a></li>
                                {/* <li><Link to="/about-us"><span className='menu-hover'>About Us</span></Link></li> */}
                                <li><a href='#services'><span className='menu-hover'>Our Servics</span></a></li>
                                <li><a href="#projects"><span className='menu-hover'>Our Projects</span></a></li>
                                {/* <li><Link to="/projects"><span className='menu-hover'>Our Projects</span></Link></li> */}
                                <li><a href='#contact'><span className='menu-hover'>Contact Us</span></a></li>
                                {/* <li><Link to="/contact-us"><span className='menu-hover'>Contact Us</span></Link></li> */}
                            </ul>
                        </nav>
                    </div>
                    <div className='footer-col-3 footer-col w-[20%] flex flex-col gap-y-6 mt-2 max-768:w-full'>
                        <h3 className='text-xl font-medium'>Our Services</h3>
                        <nav>
                            <ul className='flex flex-col gap-y-3 '>
                                <li><a href="#web-development"><span className='menu-hover'>Web Development</span></a></li>
                                {/* <li><Link to="/web-development"><span className='menu-hover'>Web Development</span></Link></li> */}
                                <li><a href="#graphic-design"><span className='menu-hover'>Graphic Design</span></a></li>
                                {/* <li><Link to="/graphic-design"><span className='menu-hover'>Graphic Design</span></Link></li> */}
                                <li><a href="#seo"><span className='menu-hover'>SEO</span></a></li>
                                {/* <li><Link to="/seo"><span className='menu-hover'>SEO</span></Link></li> */}
                                <li><a href="#social-media"><span className='menu-hover'>Social Media Marketing</span></a></li>
                                {/* <li><Link to="/social-media-marketing"><span className='menu-hover'>Social Media Marketing</span></Link></li> */}
                                <li><a href="#content-writing"><span className='menu-hover'>Content Writing</span></a></li>
                                {/* <li><Link to="/content-writing"><span className='menu-hover'>Content Writing</span></Link></li> */}
                                <li><a href="#ebook-writing"><span className='menu-hover'>Ebook Writing</span></a></li>
                                {/* <li><Link to="/ebook-writing"><span className='menu-hover'>Ebook Writing</span></Link></li> */}
                            </ul>
                        </nav>
                    </div>
                    <div className='footer-col-4 footer-col w-[30%] flex flex-col gap-y-6 mt-2 max-768:w-full'>
                        <h3 className='text-xl font-medium'>Contact Us</h3>
                        <div className="foot-contact-wrap flex flex-col gap-5">
                            {/* <div className='flex items-center gap-2'>
                                <HiOutlineMail className='text-xl' />
                                <span><a href="mailto:hello@theSourcebee.com">hello@theSourcebee.com</a></span>
                            </div> */}
                            <div className='flex items-center gap-2'>
                                <HiOutlineMail className='text-xl' />
                                <span><a href="mailto:info@theSourcebee.com">info@thesourcebee.com</a></span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <LuPhoneCall className='text-xl' />
                                <span><a href="tel:+923435355310">+92 343 5355310</a></span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <HiOutlineMapPin className='text-xl' />
                                <span><a href="https://maps.app.goo.gl/qzbzETwk5EJGdCRd9" target='blank'>Office no. 1, 2 & 3, Mezzanine Floor, Al Rahim Heights, Unit # 06, Latifabad Hyderabad</a></span>
                            </div>
                            <div className='footer-socails flex items-center gap-3'>
                                <a href='https://www.facebook.com/Sourcebee/' target='blank' aria-label='Facbook'> <FaFacebookF /> </a>
                                <a href='https://www.instagram.com/Sourcebee/' target='blank' aria-label='Instagram'>   <FaInstagram /> </a>
                                <a href='https://www.linkedin.com/company/theSourcebee/' target='blank' aria-label='Linkedin'>  <FaLinkedinIn /> </a>
                                <a href='https://wa.me/923435355310' target='blank' aria-label='Whatsapp'> <FaWhatsapp /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom py-5 flex justify-center items-center border-t border-[#ffffff40] max-575:pt-5 max-575:pb-16'>
                    <p className='text-[14px] max-575:text-center'>Copyright © 2024 Sourcebee Digtal Marketing Agency | All Rights Reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
